/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
*/

@use '@angular/material' as mat;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Compute font config
@include mat.core();
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fontConfig: mat.m2-define-typography-config(
  $font-family: 'Roboto',
  $headline-1:
    mat.m2-define-typography-level(112px, 112px, 300, 'Roboto', -0.00134em),
  $headline-2:
    mat.m2-define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, 'Roboto', 0em),
  $headline-4:
    mat.m2-define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400, 'Roboto', 0em),
  $headline-6:
    mat.m2-define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  $subtitle-1:
    mat.m2-define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  $subtitle-2:
    mat.m2-define-typography-level(12px, 24px, 500, 'Roboto', 0.0067em),
  $body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto', 0.00179em),
  $body-2: mat.m2-define-typography-level(14px, 24px, 400, 'Roboto', 0.00179em),
  $button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 0.00893em),
  $caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto', 0.00333em),
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Theme Config

body {
  --primary-color: #da0a6a;
  --primary-lighter-color: #f4b6d2;
  --primary-darker-color: #cb054d;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #da0a6a,
  lighter: #f4b6d2,
  darker: #cb054d,
  200: #da0a6a,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #c8c7c7;
  --accent-lighter-color: #efeeee;
  --accent-darker-color: #b4b3b3;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #c8c7c7,
  lighter: #efeeee,
  darker: #b4b3b3,
  200: #c8c7c7,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);
$theme-config: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
);

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
    ),
    typography: $fontConfig,
  )
);

$altTheme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
    ),
    typography: $fontConfig,
  )
);

//AIAMO THEME START
$mat-aiamo: (
  main: #098e38,
  lighter: #5ca057,
  darker: #0b7434,
  200: #098e38,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-aiamo: mat.m2-define-palette($mat-aiamo, main, lighter, darker);

$aiamoTheme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-aiamo,
      accent: $theme-accent,
    ),
    typography: $fontConfig,
  )
);

.aiamo-theme {
  @include mat.all-component-colors($aiamoTheme);
  @include mat.all-component-typographies($aiamoTheme);
}
// AIAMO THEME END

// Theme Init
.primary-theme {
  @include mat.all-component-themes($theme);
  @include mat.typography-hierarchy($theme);
}
.theme-alternate {
  @include mat.all-component-colors($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-standard-chip {
  padding: 0.35em;
  min-height: 10px;
  max-height: 10px;
  bottom: 0.35em;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-size: 16px;
}

.mat-mdc-text-field-wrapper {
  height: 3.5em !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #da0a6a;
  --mdc-switch-selected-handle-color: #da0a6a;
  --mdc-switch-selected-hover-state-layer-color: #da0a6a;
  --mdc-switch-selected-pressed-state-layer-color: #da0a6a;
  --mdc-switch-selected-focus-handle-color: #cb054d;
  --mdc-switch-selected-hover-handle-color: #cb054d;
  --mdc-switch-selected-pressed-handle-color: #cb054d;
  --mdc-switch-selected-focus-track-color: #f4b6d2;
  --mdc-switch-selected-hover-track-color: #f4b6d2;
  --mdc-switch-selected-pressed-track-color: #f4b6d2;
  --mdc-switch-selected-track-color: #f4b6d2;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-list-item__primary-text {
  white-space: normal !important;
}

.mat-datetimepicker-toggle {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  color: var(--primary-color) !important;
  background-color: #fafafa !important;
}

.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: var(--text-accent-color) !important; /* Button text color */
}
